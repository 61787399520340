html, body {
  color: #000000bd;
  margin: 0;
  padding: 0;
}

body {
  background-image: radial-gradient(circle at 20% 20%, #0000ff4d, #ffffff1a 90vh), radial-gradient(circle at 80vw 80vh, #9c6ba680, #fff 90vh);
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}

body:before {
  content: "";
  width: 100vw;
  height: 100vw;
  transform-origin: 0 100%;
  background: #0076ab;
  display: none;
  position: absolute;
  top: -100vw;
  left: 30%;
  transform: rotate(20deg);
}

.description-row {
  width: calc(100% - 70px);
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-top: -20px;
  margin-left: 70px;
  display: flex;
}

.description-wrapper {
  width: calc(33% - 12px);
  color: #fff;
  min-height: 190px;
  background-color: #0076abb3;
  border-radius: 14px;
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  column-gap: 10px;
  display: flex;
  position: relative;
}

.description-wrapper .description {
  flex-flow: row;
  flex: 1;
  justify-content: center;
  display: flex;
}

.description-wrapper .description-icon {
  color: #ffffffd4;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  font-size: 60px;
  display: flex;
}

.description-wrapper .description-text {
  flex-direction: column;
  flex: 3;
  justify-content: center;
  display: flex;
}

.testimonials-wrapper {
  text-align: justify;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

@media (max-width: 65em) {
  .testimonial {
    width: 100% !important;
    min-height: 280px !important;
  }

  .description-wrapper {
    width: calc(50% - 12px) !important;
  }
}

@media (max-width: 48em) {
  .description-wrapper {
    width: 100% !important;
  }
}

.testimonial {
  width: calc(50% - 12px);
  color: #000;
  min-height: 333px;
  background-color: #0076ab33;
  border-radius: 14px;
  flex-flow: column;
  flex: none;
  justify-content: space-between;
  column-gap: 10px;
  display: flex;
  position: relative;
}

.testimonial hr {
  border-top: 1.5px solid #0076ab99;
}

img.mini-preview {
  width: 100%;
  cursor: pointer;
  background: #000;
  border-radius: 10px;
}

header {
  margin-bottom: 1.618rem;
}

.card.ivideo-card {
  border: none;
  border-radius: 13px;
  font-family: Poppins, sans-serif;
  position: relative;
}

.card.ivideo-card.transparent {
  background-color: #0000;
}

.card.ivideo-card.separator:before {
  content: "";
  height: 1px;
  width: 30%;
  background: #a98;
  position: absolute;
  top: -20px;
  right: -24px;
}

.card.ivideo-card.separator:after {
  content: "";
  width: 7px;
  height: 7px;
  background: #a98;
  border: 1px solid #a98;
  border-radius: 50%;
  position: absolute;
  top: -23px;
  right: -24px;
}

.card.ivideo-card h4 {
  position: relative;
}

.card.ivideo-card video {
  width: 100%;
  min-width: 200px;
  border-radius: 13px;
}

.card.ivideo-card video:focus {
  outline: none;
}

.video-group {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  margin-top: -25px;
  margin-left: 70px;
  display: flex;
}

.video-container {
  flex: 1 0 58%;
}

aside {
  color: #0076ab;
  width: 370px;
  transform-origin: 100% 0;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  display: flex;
  position: relative;
  top: 0;
  left: -370px;
  transform: rotate(-90deg);
}

aside .line {
  flex: 4;
  position: relative;
}

aside .line:first-of-type {
  flex: 4;
}

aside .line:after {
  content: "";
  height: 2px;
  background: #0076ab;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

aside .section-name {
  text-align: center;
  flex: 1;
  padding: 0 10px;
}

.home-section-title-wrapper {
  color: #0076ab;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  margin: 0 10px 0 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  display: flex;
}

.home-section-title-wrapper .line {
  flex: 2;
  position: relative;
}

.home-section-title-wrapper .line:first-of-type {
  flex: 2;
}

.home-section-title-wrapper .line:after {
  content: "";
  height: 2px;
  background: #0076ab;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.home-section-title-wrapper .section-name {
  flex: 1;
  padding: 0 2px;
}

.card.character .description {
  position: relative;
}

.project-description {
  flex-direction: column;
  flex: 1 0 40%;
  justify-content: center;
  column-gap: 10px;
  display: flex;
  position: relative;
}

.project-description h4 {
  color: #000000bd;
  line-height: 1.9rem;
}

.cta-container {
  text-align: center;
  margin-top: 1.618rem;
}

.cta-container .cta {
  text-transform: uppercase;
  color: #fdfdfd;
  border-radius: 40px;
  margin: auto;
  padding: .809rem 1.618rem;
  font-family: Poppins, sans-serif;
  font-size: .9rem;
  font-weight: bold;
}

/*# sourceMappingURL=home.css.map */
