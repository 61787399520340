@import "colors";
@import "variables";

html, body {
  padding: 0;
  margin: 0;
  //height: 100%;
  color: $main-text-color;
}

body {
  //background-attachment: fixed;
  background-image:
          //radial-gradient(circle at 20% 20%, rgba(#00f, .1), rgba(#fdfdf8, .3)),
          //radial-gradient(circle at 80% 80%, rgba($accent-color, .5), #fdfdf8)
          radial-gradient(circle at 20% 20%, rgba(#00f, .3), rgba(#fff, .1) 90vh),
          radial-gradient(circle at 80vw 80vh, rgba($accent-color, .5), #fff 90vh)
;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position:relative;
  &:before{
    $side: 100vw;
    content: '';
    display:none;
    position:absolute;
    width: $side;
    height: $side;
    background:$main-bg-color;
    transform-origin: 0 100%;
    transform: rotate(20deg);
    left:30%;
    top:-$side;
  }
}
$structrure-radius: 20px;
nav.home-navbar.navbar{
}

.description-row{
  margin-top: -20px;
  margin-left: 70px;
  width: calc(100% - 70px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 20px;
  column-gap: 20px;
}

.description-wrapper{
  width: calc(33% - 12px);
  flex: 0 0 auto;
  background-color:rgba($main-bg-color, 0.7);
  color: white;
  position: relative;
  border-radius: 14px;
  min-height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  column-gap: 10px;

  .description{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    flex:1;
  }

  .description-icon{
    font-size: 60px;
    flex: 1;
    color: rgba(255, 255, 255, 0.83);;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .description-text{
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}


.testimonials-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 20px;
  column-gap: 20px;
  text-align: justify;
}


@media all and (max-width: 65em) {
  .testimonial {
    width: 100% !important;
    min-height: 280px !important;
  }

  .description-wrapper {
    width: calc(50% - 12px) !important;
  }

}

@media all and (max-width: 48em) {
  .description-wrapper {
    width: 100% !important;
  }
}

.testimonial {
  width: calc(50% - 12px);
  flex: 0 0 auto;
  background-color:rgba($main-bg-color, 0.2);
  color: black;
  position: relative;
  border-radius: 14px;
  min-height: 333px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  column-gap: 10px;

  hr {
    border-top: 1.5px solid rgba($main-bg-color, 0.6);
  }
}

img.mini-preview{
  background:#000;
  width: 100%;
  cursor: pointer;
  border-radius:10px;
}
header {
  margin-bottom: $phi*1rem;
}

.card.ivideo-card {
  position:relative;
  /*
  &:before{
    content: '';
    width: 60px;
    height: 3px;
    background-image:linear-gradient(to right, rgba(#a63, .3), rgba(#a63, .2));
    top:45px;
    left: 0;
    position: absolute;
  }
  */
  font-family: "Poppins", sans-serif;
  border-radius: $card-radius;
  border: none;

  &.transparent {
    background-color: transparent;
  }
  &.separator{
    $separator-color: #a98;
    &:before{
      content:'';
      position:absolute;
      top:-20px;
      right:-24px;
      /*
      border-top:1px solid rgba($main-text-color, .4);
      border-right:1px solid rgba($main-text-color, .4);
      border-bottom:1px solid rgba($main-text-color, .4);
      */
      height: 1px;
      width: 30%;
      background:$separator-color
    }
    &:after{
      $side:7px;
      content:'';
      width: $side;
      height: $side;
      border-radius:50%;
      border:1px solid $separator-color;
      background:$separator-color;
      position:absolute;
      top:-23px;
      right:-24px;
    }
  }
  h4{
    position:relative;
    /*
    &:before{
      content: '';
      width: 90px;
      height: 2px;
      background-image:linear-gradient(to right, rgba(#a63, .4), rgba(#a63, .2));
      bottom:-5px;
      left: 0;
      position: absolute;
    }
    */

  }
  video {
    width: 100%;
    min-width: 200px;
    border-radius: $card-radius;
    &:focus{
      outline: none;
    }
  }
}

.video-group {
  margin-top: -25px;
  margin-left: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
}
.video-container{
  flex: 1 0 58%;
}

aside{
  $size: 370px;
  $aside-color: rgba($main-bg-color, 1);
  font-family: Poppins, sans-serif;
  color:$aside-color;
  width: $size;
  display:flex;
  position:relative;
  left:-$size;
  top:0;
  transform-origin: 100% 0;
  transform:rotate(-90deg);
  text-transform:uppercase;
  font-size:20px;
  text-align: left;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  .line{
    position:relative;
    flex: 4;
    &:first-of-type{
      flex:4 ;
    }
    &:after{
      content:'';
      position:absolute;
      top:50%;
      height:2px;
      background:$aside-color;
      left:0;
      right: 0;
    }
  }
  .section-name{
    flex: 1;
    padding: 0 10px;
    text-align: center;
  }
}


.home-section-title-wrapper{
  $aside-color: rgba($main-bg-color, 1);
  font-family: Poppins, sans-serif;
  color:$aside-color;
  display:flex;
  text-transform:uppercase;
  font-size:20px;
  text-align: center;
  white-space: nowrap;
  margin: 0 10px 0 0;
  //position: relative;
  //left: 14px;
  padding: 0;
  .line{
    position:relative;
    flex: 2;
    &:first-of-type{
      flex:2;
    }
    &:after{
      content:'';
      position:absolute;
      top:50%;
      height:2px;
      background:$aside-color;
      left:0;
      right: 0;
    }
  }
  .section-name{
    flex: 1;
    padding: 0 2px;
  }
}

.card.character{
  .description{
    position: relative;
  }
}
.project-description {
  flex: 1 0 40%;
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 10px;
  h4 {
    line-height: 1.9rem;
    color: $main-text-color;
  }
}

.cta-container {
  margin-top: $phi*1rem;
  text-align: center;

  .cta {
    margin: auto;
    font-size: .9rem;
    font-family: "Poppins", sans-serif;
    border-radius: 40px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fdfdfd;
    padding: $phi*.5rem $phi*1rem;
  }
}
